module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dietrich Suess","short_name":"Dietrich Suess","start_url":"/","background_color":"#4d6888","theme_color":"#4d6888","display":"minimal-ui","icons":[],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-300,"duration":100},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(min-width: 0px)","sm":"(min-width: 768px)","md":"(min-width: 992px)","l":"(min-width: 1200px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-fresnel/gatsby-browser.js'),
      options: {"plugins":[],"breakpoints":{"sm":0,"md":768,"lg":1100,"xl":1220}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
