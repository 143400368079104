import "./src/styles/global.css"
import React from "react"
import loadable from '@loadable/component'
// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}


const BackendProvider = loadable(() => import("./src/services/backend/backend-provider"))

export const wrapRootElement = ({ element }) => {

  return (
    <BackendProvider>
      {element}
    </BackendProvider>
  )
}
